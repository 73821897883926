/* eslint-disable */
<template>
    <div class="pb-4 pt-6">
        <v-container>
            <v-row>

                <v-col cols="12" md="9" class="grey lighten-4">
                    <div v-if="userShopingCart.countItem > 0">
                        <div v-for=" item, i of userShopingCart.shopingCartDetails" :key="i">
                            <CartDetail @refresh="initialize" :item="item" />
                            <v-divider></v-divider>
                        </div>

                    </div>
                    <div v-else class="text-center">
                        <p class="mb-2 text-center">Your shopping cart is currently empty. Continue browsing our products to
                            find items you'd like to purchase.</p>
                      å  <router-link to="/pricing" class="rounded mt-2 ">Click here to see pricing</router-link>
                    </div>

                </v-col>
                <v-col cols="12" md="3">
                    <div>
                        <div>
                            <div>
                                <div class="grey white--text px-3 py-3 rounded darken-3">
                                    <h3>Your Subtotal</h3>
                                </div>
                                <div class=" px-3 py-3  grey lighten-4">
                                    <p class="mb-2">Subtotal $ {{ userShopingCart.totalAmount }}</p>
                                    <v-divider></v-divider>
                                    <div class="mt-5">
                                        <v-btn :disabled="userShopingCart.countItem < 1"
                                            class="red white--text elevation-0 w-100" @click="goTo('/checkoutshopingcart')">Confirm order</v-btn>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import { methods, rules } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";
import CartDetail from "../../components/user/CartDetail.vue";

// import {
// addToShopingCart,
// } from "@/api/user.js";

export default {
    components: { CartDetail },
    props: {},
    data() {
        return {
            quantity: 1,
            rules: rules,
            loading: false,
        };
    },
    watch: {},
    computed: {
        ...mapGetters([
            "userShopingCart",
        ]),
    },
    methods: {
        ...mapActions([
            "getMyShopingCart",
        ]),
        goTo(link) {
            this.$router.push(link);
        },
        async initialize() {
            try {
                this.loading = true;
                await this.getMyShopingCart();
            }
            catch (error) {
                console.log("initialize ", error);
                methods.dispatchError(this.$store);
            }
            finally {
                this.loading = false;
            }
        },
    },
}
</script>