/* eslint-disable */
<template>
    <div class="py-3">
        <!-- {{ item }} -->
        <div v-if="item">
            <v-row>
                <v-col cols="12" md="12" class="grey lighten-4">
                    <div>
                        <h3 class="mb-2"></h3>

                        <div class="">
                            <div class="princing-card  container  " :class="''">
                                <div>
                                    <div class=" ">
                                        <div class="d-flex justify-space-between">
                                            <h2 class="card-title">{{ item.pricing.name }}</h2>
                                            <div>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">

                                                        <v-btn @click="removeFromCart(item)" v-bind="attrs" v-on="on" icon
                                                            color="grey"><v-icon>mdi-close</v-icon></v-btn>

                                                    </template>
                                                    <span>Remove from cart</span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                        <div class="d-flex mt-3">

                                            <div class="princing mr-3 ">
                                                <span class="unit">{{ '$' }}</span>
                                                <span class="price">{{ item.amount }}</span>
                                            </div>
                                            <div class="princing-description d-flex align-center  ">
                                                <div>
                                                    <p class="mb-0 ">
                                                        <span class="ml-2">( {{ item.quantity }} {{ item.billingCycle }}
                                                            )</span>

                                                    </p>

                                                </div>
                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>
                            <div class="princing-detail container">
                                <p class="princing-detail-title mt-2">Plan Detail</p>
                                <div>
                                    <ul class="list ">
                                        <li class="list-item grey lighten-4"><span class="">✓</span>
                                            {{ item.workspaceAdditionalSubscriptionCustomization.name }} Workspace(s)
                                        </li>
                                        <li class="list-item grey lighten-4"><span class="">✓</span>
                                            {{ item.memberPerWorkspaceAdditionalSubscriptionCustomization.name }} Member /
                                            Workspace
                                        </li>
                                        <li class="list-item grey lighten-4"><span class="">✓</span>
                                            {{ item.projectPerWorkspaceAdditionalSubscriptionCustomization.name }} Project /
                                            Workspace
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </v-col>

                <!-- <v-col cols="3" md="3">
                    <div>
                        <h3 class="mb-2">Total</h3>
                        <p class="mb-0 font-weight-bold"> $ {{ item.amount }}</p>
                    </div>
                </v-col> -->
                <!-- <v-col cols="3" md="3" class="d-flex justify-center align-center grey lighten-5">
                    <div class="">
                        <div class=" h-100">
                            <v-btn color="red" class="elevation-0 white--text" rounded> <v-icon class="mr-2"
                                    small>mdi-close</v-icon> Remove From Cart</v-btn>
                        </div>
                    </div>
                </v-col> -->
            </v-row>
        </div>
    </div>
</template>
<script>

import { methods, rules } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";

import {
    removeFromCart,
} from "@/api/user.js";

export default {
    props: {
        item: {
            type: Object,
            required: false
        }

    },

    data() {
        return {
            quantity: 1,
            rules: rules,
            loading: false,
        }
    },
    watch: {},
    computed: {
        ...mapGetters([
            "userShopingCart",
        ]),
    },
    methods: {
        ...mapActions([
            "getMyShopingCart",
        ]),
        async initialize() {
            try {
                this.loading = true;
                await this.getMyShopingCart();
            } catch (error) {
                console.log("initialize ", error);
                methods.dispatchError(this.$store);
            } finally {
                this.loading = false;

            }
        },
        async removeFromCart(item){
            try {
                this.loading = true;
           
                await removeFromCart({
                    id:item._id
                })

                this.$emit("refresh")
            } catch (error) {
                console.log("initialize ", error);
                methods.dispatchError(this.$store);
            } finally {
                this.loading = false;

            }
        }
    }

}
</script>

<style>
.princing-card {
    border-radius: 8px;
}

.princing-card .card-title {
    font-size: 1.6rem;
    font-weight: 900;

}

.princing-card .card-description {
    font-size: 1.3rem;
    font-weight: 500;

}

.princing {
    position: relative
}

.princing .unit {
    position: absolute;
    top: 20%;
    left: 0;

}

.princing .price {
    font-size: 4.3rem;
    font-weight: 900;
    margin-left: .5rem;
}

.princing-description p {
    font-size: 1rem;
    font-weight: 400;
}

.princing-detail-title {
    font-size: .9rem;
    font-weight: 700;
}


.princing-detail .list {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    font-size: 0.75rem;
    color: rgb(30, 30, 30);
    font-weight: 500;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.princing-detail .list .list-item .check {
    margin-right: 0.25rem;
    font-size: 1rem;
    color: rgb(10, 10, 10);
    font-weight: 900;
}

.button {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 0.5rem;
    padding-inline: 1.25rem;
    background-color: rgb(0, 0, 0);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 15px;
}

.universeicon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
}

.button:hover {
    transform: scale(1.05);
    border-color: #fff9;
}

.button:hover .universeicon {
    transform: translate(4px);
}

.button:hover::before {
    animation: shine 1.5s ease-out infinite;
}

.button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes shine {
    0% {
        left: -100px;
    }

    60% {
        left: 100%;
    }

    to {
        left: 100%;
    }
}
</style>